import CryptoJS from 'crypto-js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '../hooks/use-previous';
import { load } from '@fingerprintjs/botd';
import { AnalyticsVisitorReferrer } from '@law-connect/types';
import { security } from '../redux/api/security';

// Collect AskMaxwell = _cltAm
const sessionSecret = 'askmaxwell-collector';

// Function to encrypt data using AES and return a base64-encoded string
const aesEncrypt = (data: string, key: string) => {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(data, key).toString())
  );
};

const botdPromise = load({
  // We disable monitoring so that the botd library doesn't send user identifiable data to the server
  monitoring: false,
});

export const collect = async (args?: {
  path?: string;
  userId?: string;
  referer?: AnalyticsVisitorReferrer;
}) => {
  try {
    const {
      path = window.location.pathname,
      userId,
    } = args;
    const isBot = (
      await botdPromise
        .then((botd) => botd.detect())
        .then((result) => result)
        .catch((_e) => {
          return { bot: false };
        })
    ).bot;

    if (isBot) {
      // We do not track bots
      return;
    }

    const data = {
      userId,
      data: {
        path,
        referrer: args.referer,
      },
    };

    // Encrypt the data
    const encrypted: string = aesEncrypt(
      JSON.stringify(data),
      sessionSecret
    );

    const headers: HeadersInit = {
      'Content-Type': 'text/plain',
    };

    let token = '';
    try {
      token = await security.getAccessToken();
    } catch (e) {
      // do nothing, no token available.
    }
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Post data to /collect endpoint
    await fetch('/api/public/collect', {
      method: 'POST',
      body: encrypted,
      credentials: 'include',
      headers,
    });
  } catch (e) {
    console.error('Error collecting analytics', e);
  }
};

export interface Props {
  userId?: string;
  sessionId?: string;
}

// This collects page views
export const Collector: React.FC<Props> = (props) => {
  const { userId, sessionId } = props;
  const location = useLocation();

  const prevLocation = usePrevious(location);
  const prevSessionId = usePrevious(sessionId);

  useEffect(() => {
    if (!prevLocation || prevLocation.pathname !== location.pathname) {
      // Get utm values from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source');
      const utmMedium = urlParams.get('utm_medium');
      const utmCampaign = urlParams.get('utm_campaign');
      const utmTerm = urlParams.get('utm_term');
      const utmContent = urlParams.get('utm_content');

      // Extract the domain (including sub domains and port) from the url
      const url = document.referrer || window.location.href;
      const host = new URL(url);

      const referer = {
        host: host ? `${host.hostname}${host.port ? `:${host.port}` : ''}` : '',
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
      };

      collect({
        userId,
        path: location.pathname,
        referer,
      });
    }
  }, [location, prevLocation, prevSessionId, sessionId, userId]);

  return null;
};
