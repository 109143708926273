import { createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import {
  Prematter,
  File,
  LegalCaseState,
  PublicPrematter,
} from '@law-connect/types';

interface PrematterState {
  prematters: Prematter[];
  files: { [id: string]: File[] };
  publicPrematter: PublicPrematter | null;
  pending: {
    fetch?: boolean;
    fetchById?: boolean;
    fetchPublicById?: boolean;
    delete?: boolean;
    update?: boolean;
    regenerate?: boolean;
    addQuestion?: { [id: string]: boolean };
    deleteQuestion?: { [id: string]: boolean };
    updateExtendedForm?: { [id: string]: boolean };
    canVerify?: boolean;
    sendVerify?: boolean;
  };
  errors: {
    fetch?: string | null;
    fetchById?: string | null;
    fetchPublicById?: string | null;
    delete?: string | null;
    update?: string | null;
    regenerate?: string | null;
    addQuestion?: { [id: string]: string };
    deleteQuestion?: { [id: string]: string };
    updateExtendedForm?: { [id: string]: string };
    canVerify?: string | null;
    sendVerify?: string | null;
  };
  canVerify: {
    [id: string]: boolean;
  };
}

const initialState: PrematterState = {
  prematters: [],
  publicPrematter: null,
  files: {},
  pending: {
    addQuestion: {},
    deleteQuestion: {},
    updateExtendedForm: {},
  },
  errors: {
    addQuestion: {},
    deleteQuestion: {},
    updateExtendedForm: {},
  },
  canVerify: {},
};

const prematterSlice = createSlice({
  name: 'prematter',
  initialState,
  // you cant have a reducer name match the thunk action
  reducers: {
    updatePrematters: (state, action) => {
      state.prematters = state.prematters.map((prematter) => {
        if (prematter.id === action.payload.id) {
          return action.payload;
        }
        return prematter;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.prematter.fetch.pending, (state) => {
      state.pending.fetch = true;
      state.errors.fetch = null;
    });
    builder.addCase(actions.prematter.fetch.fulfilled, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = null;
      state.prematters = action.payload;
    });
    builder.addCase(actions.prematter.fetch.rejected, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.fetchById.pending, (state) => {
      state.pending.fetchById = true;
      state.errors.fetchById = null;
    });
    builder.addCase(actions.prematter.fetchById.fulfilled, (state, action) => {
      state.pending.fetchById = false;
      state.errors.fetchById = null;
      const replaceIndex = state.prematters.findIndex(
        (p) => p.id === action.payload.id
      );
      if (replaceIndex !== -1) {
        state.prematters[replaceIndex] = action.payload;
      } else {
        state.prematters.push(action.payload);
      }
    });
    builder.addCase(actions.prematter.fetchById.rejected, (state, action) => {
      state.pending.fetchById = false;
      state.errors.fetchById = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.update.pending, (state) => {
      state.pending.update = true;
      state.errors.update = null;
    });
    builder.addCase(actions.prematter.update.fulfilled, (state, action) => {
      state.pending.update = false;
      state.errors.update = null;
      state.prematters = state.prematters.map((prematter) => {
        if (prematter.id === action.payload.id) {
          return action.payload;
        }
        return prematter;
      });
    });
    builder.addCase(actions.prematter.update.rejected, (state, action) => {
      state.pending.update = false;
      state.errors.update = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.delete.pending, (state) => {
      state.pending.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(actions.prematter.delete.fulfilled, (state, action) => {
      state.pending.delete = false;
      state.errors.delete = null;
      state.prematters = state.prematters.filter(
        (prematter) => prematter.id !== action.meta.arg.id
      );
    });
    builder.addCase(actions.prematter.delete.rejected, (state, action) => {
      state.pending.delete = false;
      state.errors.delete = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.regenerate.pending, (state) => {
      state.pending.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(actions.prematter.regenerate.fulfilled, (state, action) => {
      state.pending.regenerate = false;
      state.errors.regenerate = null;
      state.prematters = state.prematters.map((prematter) => {
        if (prematter.id === action.payload.id) {
          return action.payload;
        }
        return prematter;
      });
    });
    builder.addCase(actions.prematter.regenerate.rejected, (state, action) => {
      state.pending.regenerate = false;
      state.errors.regenerate = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.addQuestion.pending, (state, action) => {
      state.errors.addQuestion[action.meta.arg.id] = null;
      state.pending.addQuestion[action.meta.arg.id] = true;
    });
    builder.addCase(
      actions.prematter.addQuestion.fulfilled,
      (state, action) => {
        state.pending.addQuestion[action.meta.arg.id] = false;
        state.prematters = state.prematters.map((prematter) => {
          if (prematter.id === action.payload.id) {
            return action.payload;
          }
          return prematter;
        });
      }
    );
    builder.addCase(actions.prematter.addQuestion.rejected, (state, action) => {
      state.pending.addQuestion[action.meta.arg.id] = false;
      state.errors.addQuestion[action.meta.arg.id] =
        (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(
      actions.prematter.deleteQuestion.pending,
      (state, action) => {
        state.errors.deleteQuestion[action.meta.arg.id] = null;
        state.pending.deleteQuestion[action.meta.arg.id] = true;
      }
    );
    builder.addCase(
      actions.prematter.deleteQuestion.fulfilled,
      (state, action) => {
        state.pending.deleteQuestion[action.meta.arg.id] = false;
        state.prematters = state.prematters.map((prematter) => {
          if (prematter.id === action.meta.arg.id) {
            return {
              ...prematter,
              context: {
                ...prematter.context,
                questions: prematter.context.questions.filter(
                  (question) => question.id !== action.meta.arg.questionId
                ),
              },
            };
          }
          return prematter;
        });
      }
    );
    builder.addCase(
      actions.prematter.deleteQuestion.rejected,
      (state, action) => {
        state.pending.deleteQuestion[action.meta.arg.id] = false;
        state.errors.deleteQuestion[action.meta.arg.id] =
          (action.payload as string) ?? 'unknown error';
      }
    );
    builder.addCase(
      actions.prematter.updateExtendedForm.pending,
      (state, action) => {
        state.pending.updateExtendedForm[action.meta.arg.id] = true;
        state.errors.updateExtendedForm[action.meta.arg.id] = null;
      }
    );
    builder.addCase(
      actions.prematter.updateExtendedForm.fulfilled,
      (state, action) => {
        state.pending.updateExtendedForm[action.meta.arg.id] = false;
        let updated = false;
        state.prematters = state.prematters.map((prematter) => {
          if (prematter.id === action.meta.arg.id) {
            updated = true;
            return action.payload;
          }
          return prematter;
        });

        if (!updated) {
          state.prematters.push(action.payload);
        }
      }
    );
    builder.addCase(
      actions.prematter.updateExtendedForm.rejected,
      (state, action) => {
        state.pending.updateExtendedForm[action.meta.arg.id] = false;
        state.errors.updateExtendedForm[action.meta.arg.id] =
          (action.payload as string) ?? 'unknown error';
      }
    );

    builder.addCase(actions.prematter.canVerify.pending, (state, action) => {
      state.pending.canVerify = true;
      state.errors.canVerify = null;
    });
    builder.addCase(actions.prematter.canVerify.fulfilled, (state, action) => {
      state.pending.canVerify = false;
      state.errors.canVerify = null;
      state.canVerify[action.meta.arg.id] = action.payload;
    });
    builder.addCase(actions.prematter.canVerify.rejected, (state, action) => {
      state.pending.canVerify = false;
      state.errors.canVerify = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.prematter.sendVerify.pending, (state, action) => {
      state.pending.sendVerify = true;
      state.errors.sendVerify = null;
    });
    builder.addCase(actions.prematter.sendVerify.fulfilled, (state, action) => {
      state.pending.sendVerify = false;
      state.errors.sendVerify = null;
      const findIndex = state.prematters.findIndex(
        (p) => p.id === action.meta.arg.id
      );
      if (findIndex !== -1) {
        state.prematters[findIndex] = {
          ...state.prematters[findIndex],
          state: LegalCaseState.VerificationPending,
        };
      }
    });
    builder.addCase(actions.prematter.sendVerify.rejected, (state, action) => {
      state.pending.sendVerify = false;
      state.errors.sendVerify = (action.payload as string) ?? 'unknown error';
    });

    builder.addCase(actions.prematter.updateAiLawyer.pending, (state) => {
      state.pending.update = true;
      state.errors.update = null;
    });
    builder.addCase(
      actions.prematter.updateAiLawyer.fulfilled,
      (state, action) => {
        state.pending.update = false;
        state.errors.update = null;
        state.prematters = state.prematters.map((prematter) => {
          if (prematter.id === action.payload.id) {
            return action.payload;
          }
          return prematter;
        });
      }
    );
    builder.addCase(actions.prematter.fetchPublicById.pending, (state) => {
      state.pending.fetchPublicById = true;
      state.errors.fetchPublicById = null;
    });
    builder.addCase(
      actions.prematter.fetchPublicById.fulfilled,
      (state, action) => {
        state.pending.fetchPublicById = false;
        state.errors.fetchPublicById = null;
        state.publicPrematter = action.payload;
      }
    );
    builder.addCase(
      actions.prematter.fetchPublicById.rejected,
      (state, action) => {
        state.pending.fetchPublicById = false;
        state.errors.fetchPublicById =
          (action.payload as string) ?? 'unknown error';
      }
    );
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const prematterActions = prematterSlice.actions;
export const prematterReducer = prematterSlice.reducer;
